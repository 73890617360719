<template>
  <div class="row mt-5">
    <div class="col-12">
      <KTCard>
        <template v-slot:title>
          <h6 class="mb-0">Personal Posts</h6>
        </template>
        <template v-slot:body>
          <b-table bordered responsive :items="[]" :fields="fields" show-empty>
            <template v-slot:empty>
              <center>No data</center>
            </template>

            <template v-slot:cell(no)="row">
              {{ row.index + 1 }}
            </template>
          </b-table>
        </template>
      </KTCard>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import KTCard from "@/view/content/Card.vue"
export default {
  components: {
    KTCard,
  },
  data() {
    return {
      fields: [
        { key: "no", label: "#" },
        {
          key: "link",
          label: "Link",
        },
        {
          key: "postDate",
          label: "Post Date",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Personal Posts" }])
  },
}
</script>

<style></style>
